.Title {
  font-size: 30px;
  margin-right: auto;

  > .Literal {
    color: #444;
    text-transform: uppercase;
    font-size: 24px;
  }

  > .JS {
    color: #0ba8ff;
    font-size: 24px;
    margin-left: 2.5px;
  }
}
