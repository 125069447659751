@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro|Quicksand');

// CSS RESET
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
form,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}

body {
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  background-color: #f6f6f6;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
// CSS RESET END

.App {
  position: relative;
  transition: all 0.3s ease-in;
  width: 100%;
  padding: 10px;
  @media only screen and (max-width: 900px) {
    padding: 0px;
    padding-top: 60px;
  }
  @media only screen and (min-width: 901px) {
    padding: 0px;
    padding-top: 60px;
  }
  @media only screen and (min-width: 1100px) and (max-width: 1399px) {
    padding-left: 215px;
    padding-right: 10%;
    padding-top: 10px;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1699px) {
    padding-left: 215px;
    padding-right: 15%;
    padding-top: 10px;
  }
  @media only screen and (min-width: 1700px) and (max-width: 1899px) {
    padding-left: 215px;
    padding-right: 20%;
    padding-top: 10px;
  }
  @media only screen and (min-width: 1800px) and (max-width: 1999px) {
    padding-left: 215px;
    padding-right: 25%;
  }
  @media only screen and (min-width: 2000px) {
    padding-left: 215px;
    padding-right: 30%;
    padding-top: 10px;
  }
}
