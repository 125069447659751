.Logo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;
  height: 50px;
  width: 50px;
  font-size: 20px;
  font-weight: bold;
  padding: 0 2.5px;
  background-color: #fff;
  border: 1px solid darken(white, 20%);
  border-radius: 3px;
  line-height: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  margin-right: 8px;

  > .L {
    color: #333;
  }

  > .J {
    color: #0ba8ff;
  }

  > .S {
    color: #0ba8ff;
  }
}
