.Content {
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: row;
	width: 100%;
	border-radius: 3px;
	@media only screen and (max-width: 1100px) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
	}

	> .right {
		flex: 1;
		background-color: white;
		border: 1px solid #eee;
		margin-left: 10px;
		padding: 10px 0;
		border: 1px solid darken(white, 20%);
		border-radius: 3px;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
		position: relative;
		width: 100%;
		@media only screen and (max-width: 1100px) {
			margin-left: 0;
			margin-top: 10px;
			border: none;
			border-radius: 0px;
		}

		@keyframes float {
			from {
				bottom: 10px;
			}
			to {
				bottom: 30px;
			}
		}

		> .to-top {
			position: fixed;
			right: 12px;
			bottom: 10;
			background-color: white;
			color: #333;
			border: 1px solid darken(white, 30%);
			border-radius: 6px;
			box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
			width: 50px;
			height: 50px;
			text-align: center;
			padding-top: 13px;
			cursor: pointer;
			transition: all 0.1s ease-in;
			white-space: nowrap;
			font-size: 30px;
			line-height: 1;
			animation: float 0.5s linear 1s infinite alternate;
			&:hover {
				background-color: darken(white, 20%);
			}
		}

		> pre {
			position: relative;
			width: 100%;
			margin: 10px auto;
			// margin-bottom: 0px;
			white-space: pre;
			word-wrap: normal;
			word-break: normal;
			border-radius: 0px;

			> code {
				width: auto;
				white-space: pre;
				word-wrap: normal;
				word-break: normal;
				border-radius: 0px;
			}
		}

		> h1 {
			color: #333;
			font-size: 32px;
			font-weight: bold;
			padding: 0px 20px;
		}

		> h2 {
			color: #333;
			margin-top: 20px;
			font-size: 32px;
			font-weight: bold;
			width: 100%;
			// border-top: 1px solid rgba(0, 0, 0, 0.05);
			margin-bottom: 10px;
			padding: 0px 20px;
			padding-top: 30px;
		}

		> h3 {
			color: lighten(#333, 10%);
			margin-top: 10px;
			margin-bottom: 5px;
			font-size: 25px;
			font-weight: bold;
			width: 100%;
			padding: 0px 20px;
			padding-top: 10px;
		}

		> h4 {
			color: lighten(#333, 15%);
			margin-top: 10px;
			margin-bottom: 5px;
			font-size: 18px;
			font-weight: bold;
			width: 100%;
			padding: 0px 20px;
			padding-top: 10px;
		}

		> a {
			color: #0ba8ff;
			text-decoration: none;
			padding: 5px;
			transition: all 0.1s ease-in;
			white-space: nowrap;
			padding: 0px 10px;

			&:hover {
				background-color: darken(#eee, 20%);
				color: darken(#0ba8ff, 20%);
			}
		}

		> ul {
			padding: 0px 20px;

			> li {
				margin: 5px;
				> .title {
					font-size: 20px;
					font-weight: bold;
					padding-right: 5px;
				}
			}
		}

		> .list {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			padding: 20px;

			> div {
				padding-bottom: 5px;
			}

			> a {
				color: #0ba8ff;
				text-decoration: none;
				padding: 5px;
				transition: all 0.1s ease-in;
				white-space: nowrap;

				&:hover {
					background-color: darken(#eee, 20%);
					color: darken(#0ba8ff, 20%);
				}
			}
		}

		> p {
			padding: 0px 20px;
			line-height: 2;
			margin-bottom: 10px;

			&.-important {
				background-color: #eee;
				padding: 10px;
			}
			> strong {
				font-weight: bold;
			}
			> code {
				background-color: #eee;
				border: 1px solid darken(#eee, 10%);
				color: orangered;
				font-family: 'Source Sans Pro', Consolas, Monaco, 'Andale Mono',
					'Ubuntu Mono', monospace;
				font-size: 14px;
				padding: 1px 5px;
				margin: 3px;
				border-radius: 6px;
				box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
			}

			> a {
				color: #0ba8ff;
				text-decoration: none;
				padding: 5px;
				transition: all 0.1s ease-in;
				white-space: nowrap;

				&:hover {
					background-color: darken(#eee, 20%);
					color: darken(#0ba8ff, 20%);
				}
			}
		}
	}
}
