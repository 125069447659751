.Navbar {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 200px;
	background-color: white;
	border: 1px solid darken(white, 20%);
	border-radius: 3px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
	position: fixed;
	top: 10px;
	left: 10px;
	z-index: 1;
	@media only screen and (max-width: 1100px) {
		width: 100%;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
		flex-direction: column;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		border-radius: 0px;
		border: none;
		border-bottom: 1px solid darken(white, 20%);
	}

	> .top {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 10px;
		width: 100%;
		@media only screen and (max-width: 1100px) {
			flex-direction: row;
		}

		> .title {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			width: 100%;
		}

		> .version {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: row;
			width: 100%;
			@media only screen and (max-width: 1100px) {
				align-items: center;
				justify-content: center;
				width: auto;
			}

			> .blue {
				padding-left: 5px;
				font-weight: bold;
				color: #0ba8ff;
			}
		}

		> .menu-button {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-left: auto;
			line-height: 0.3;
			border: 1px solid darken(#eee, 20%);
			box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
			cursor: pointer;
			width: 50px;
			height: 40px;
			border-radius: 4px;
			transition: all 0.1s ease-in;
			font-weight: bolder;
			font-size: 20px;
			overflow: hidden;
			margin-left: 20px;
			&:hover {
				color: #0ba8ff;
				background-color: darken(#eee, 10%);
			}
			@media only screen and (min-width: 1100px) {
				display: none;
			}
		}
	}

	> .menu {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		flex-wrap: nowrap;
		padding: 0;
		margin: 0;
		width: 100%;
		transition: all 0.1s ease-in-out;
		height: auto;
		@media only screen and (max-width: 1100px) {
			overflow: auto;
			&.-show {
				max-height: 500px;
			}

			&.-hide {
				max-height: 0;
				overflow: hidden;
				-webkit-transition: max-height 0.3s;
				-moz-transition: max-height 0.3s;
				transition: max-height 0.3s;
			}
		}

		> a {
			text-decoration: none;
			color: #333;
			width: 100%;
			white-space: nowrap;
			padding: 10px;
			transition: all 0.1s ease-in;
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;
			font-weight: bold;

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				background-color: #eee;
				color: #0ba8ff;
				border-color: darken(#eee, 10%);
			}
		}

		> .links {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			background-color: white;
			border-top: 1px solid darken(white, 20%);
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
			width: 100%;
			height: 50px;
			padding: 0;
			@media only screen and (max-width: 1100px) {
				box-shadow: none;
				border: none;
				height: 70px;
			}

			> a {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				color: #333;
				transition: all 0.1s ease-in;
				font-size: 18px;
				width: 100%;
				height: 100%;
				text-align: center;
				text-decoration: none;

				&:hover {
					background-color: #eee;
					color: #0ba8ff;
					border-color: darken(#eee, 10%);
				}
			}
		}
	}
}
